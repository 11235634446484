import React from 'react';

import {images} from '../../constants'
import './Header.css';
import {SubHeading} from "../../components";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
        <SubHeading title="Event starts here"/>
        <h1 className="app__header-h1">Party Rental Services</h1>
        <p className="p__opensans" style={{margin: '2rem 0'}}>
            Whether you have an upcoming event and you want to get a sense for what we carry or you have an idea and mind and want to see if we can help you pull it off, we'll be happy to show you around and share some of our inventory with you.
        </p>
        <button type="button" className="custom__button">Explore Services</button>
    </div>
    <div className="app__wrapper_img">
        <img src={images.liner1}/>
    </div>
  </div>
);

export default Header;
