import React from 'react';

import { GiHamburgerMenu} from "react-icons/gi";

import images from "../../constants/images";
import './Navbar.css';

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = React.useState(false);
    return (
        <nav className="app__navbar">
            <div className="app__navbar-logo">
                <img id="logo" src={images.wcrlogo} alt="wcr logo"/>
            </div>
            <ul className="app__navbar-links">
                <li className="p__opensans">
                    <a href="#home">Home</a>
                </li>
                <li className="p__opensans">
                    <a href="#products">Products</a>
                </li>
                <li className="p__opensans">
                    <a href="#services">Services</a>
                </li>
                <li className="p__opensans">
                    <a href="#gallery">Photo Gallery</a>
                </li>
                <li className="p__opensans">
                    <a href="#contact">Contact</a>
                </li>
            </ul>
            <div className="app__navbar-contact">
                <p>Phone: (408)475-3555</p>
            </div>

            <div className="app__navbar-smallScreen">
                <GiHamburgerMenu id="gihm" color="#fff" fontSize={27} onClick={() => [setToggleMenu(true), document.getElementById('gihm').style.cssText = 'color: black',document.getElementById('logo').style.display = 'none']}/>

                {toggleMenu && (
                    <div className="app__navbar-smallScreen_overlay flex__center slide-bottom">
                        <img src={images.wcrlogo} alt="wcr logo" width={50} className="overlay__close" onClick={() => [setToggleMenu(false), document.getElementById('gihm').style.cssText = 'color: white', document.getElementById('logo').style.display = 'flex']}/>
                        <ul className="app__navbar-smallScreen_links">
                            <li className="p__opensans">
                                <a href="#home">Home</a>
                            </li>
                            <li className="p__opensans">
                                <a href="#products">Products</a>
                            </li>
                            <li className="p__opensans">
                                <a href="#Photo">Photo Gallary</a>
                            </li>
                            <li className="p__opensans">
                                <a href="#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
